import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import HomepageTile from '../components/HomepageTile';
import Carousel from '../components/Carousel';
import ContactForm from '../components/ContactForm';
import { HomepageCallout } from 'gatsby-theme-carbon';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <br />
    <Row mdxType="Row">
      <Column colLg={6} colMd={4} noGutterSm mdxType="Column">
  <HomepageTile ratio="3:2" image="/images/media.jpg" actionIcon="no-icon" mdxType="HomepageTile">
    <HomepageTile ratio={{
            default: '3:1',
            md: '3:2',
            lg: '3:1'
          }} theme="dark" title="Upgrading to PHP 7 should be at the top of your to-do list" actionIcon="article" link="/blog/migrate-to-php-7" mdxType="HomepageTile" />
    </HomepageTile>
      </Column>
    </Row>
    <Row mdxType="Row">
  <Column noGutterSmLeft mdxType="Column">
        <hr></hr>
        <h3>{`Services`}</h3>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colLg={4} colMd={4} noGutterSmLeft mdxType="Column">
    <HomepageTile marginTop="20px" hoverDark ratio="1:1" theme="dark" subtitle="Services" title="Enterprise Software Development" backgroundColor="#6e67ff" transparentImage="images/case.svg" //      identityIcon="images/apple.svg"
        actionIcon="article" link="/services/enterprise" mdxType="HomepageTile" />
    <HomepageTile marginTop="20px" hoverDark ratio="1:1" theme="dark" subtitle="Services" title="Progressive Web" backgroundColor="#9267ff" transparentImage="images/web.svg" actionIcon="article" link="/services/progressive" mdxType="HomepageTile" />
  </Column>
  <Column colLg={4} colMd={4} noGutterSmLeft mdxType="Column">
    <HomepageTile marginTop="20px" hoverDark ratio="1:1" theme="dark" subtitle="Services" title="Mobile Development" backgroundColor="#6784ff" transparentImage="images/mobile.svg" actionIcon="article" link="/services/mobile" mdxType="HomepageTile" />
    <HomepageTile marginTop="20px" hoverDark ratio="1:1" theme="dark" subtitle="Services" title="Team Augmentation" backgroundColor="#6e67ff" transparentImage="images/staff.svg" actionIcon="article" link="/services/staff" mdxType="HomepageTile" />
  </Column>
  <Column colLg={4} colMd={4} noGutterSmLeft mdxType="Column">
    <HomepageTile marginTop="20px" hoverDark ratio="1:1" theme="dark" subtitle="Services" title="Application Development" backgroundColor="#67a8ff" transparentImage="images/apps.svg" actionIcon="article" link="/services/application" mdxType="HomepageTile" />
    <HomepageTile marginTop="20px" hoverDark ratio="1:1" theme="dark" subtitle="Services" title="Software Prototyping" backgroundColor="#6784ff" transparentImage="images/proto.svg" actionIcon="article" link="/services/prototyping" mdxType="HomepageTile" />
  </Column>
    </Row>
    <Row mdxType="Row">
      <Column noGutterSmLeft mdxType="Column">
        <hr></hr>
        <h3>{`Technologies`}</h3>
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem'
        }}>
          <img {...{
            "src": "/images/tech.svg",
            "alt": "tech"
          }}></img>
        </div>
        <br /><br />
        <hr></hr>
        <br /><br />
      </Column>
      <Column colMd={3} colLg={6} className="bx--type-expressive-heading-03" noGutterSmLeft mdxType="Column">
        <p>{`Do you need technology or a new concept for the business? `}</p>
        <p>{`Contact us and we’ll help you plan your route. Our team will contact you for more information about your requirements and a detailed analysis of your needs. `}</p>
        <p>{`We will use your information to make a detailed evaluation proposal available to you.`}</p>
      </Column>
      <Column colMd={3} colLg={5} offsetMd={1} offsetLg={1} noGutterSmLeft mdxType="Column">
Please fill out the form below and let us talk to you about your idea. You will be kept confidential with your details.
        <br />
        <ContactForm mdxType="ContactForm" />
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      